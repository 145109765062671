"use client"

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Spacer,
  Text,
  Theme,
  useTheme,
} from "@chakra-ui/react"
import { useRouter } from "next/navigation"

import { LightningIcon } from "@/components/icons/LightningIcon"
import PowerUpHeroImage from "./PowerUpHeroImage"
import {
  SPANOptions,
  exploreLandingFooter,
  howPowerUpWorks_Section1,
  howPowerUpWorks_Section2,
  howPowerUpWorks_Section3,
  howPowerUpWorks_Section4,
  upgradeOptions,
} from "./textConstants"

const Landing = () => {
  const theme = useTheme() as Theme
  const router = useRouter()

  return (
    <Box>
      <Flex flexDir={"column"}>
        {/* First section with hero image background */}
        <PowerUpHeroImage isButtonShown={true} />

        {/* Second section with green background */}
        <Box
          minHeight={600}
          bg={theme.colors.green["50"]}
          display={"flex"}
          alignItems={"center"}
        >
          <Container maxW={"3xl"} minWidth={"xs"} paddingY={"2rem"}>
            <Heading
              mx={{ sm: "2rem" }}
              color={"black.500"}
              fontSize={{ base: "6vw", sm: "3xl" }}
            >
              How PowerUp works
            </Heading>
            <Flex mt="2rem" flexDir={{ base: "column", sm: "row" }}>
              <Spacer maxWidth={"2rem"} />
              <Box flex="1">
                <Text
                  fontWeight={"semibold"}
                  color={"black.500"}
                  fontSize={{ base: "4vw", xs: "xs", md: "sm" }}
                >
                  {howPowerUpWorks_Section1}
                  <br />
                  <br />
                  {howPowerUpWorks_Section2}
                </Text>
              </Box>
              <Spacer minHeight={5} maxWidth={10} />
              <Box flex="1">
                <Text
                  fontWeight={"semibold"}
                  color="black.500"
                  fontSize={{ base: "4vw", xs: "xs", md: "sm" }}
                >
                  {howPowerUpWorks_Section3}
                  <br />
                  <br />
                  {howPowerUpWorks_Section4}
                </Text>

                <Button
                  data-cy="explore-button-two"
                  mt={"2rem"}
                  onClick={() => {
                    router.push("/configurations/new")
                  }}
                  variant={"outline"}
                  border="1px solid black"
                  borderRadius={"20px"}
                  color="black.500"
                  size={{ xs: "sm", md: "md" }}
                  _hover={{ bg: "#108C4F" }}
                >
                  Explore PowerUp
                </Button>
              </Box>
              <Spacer maxWidth={"2rem"} />
            </Flex>
          </Container>
        </Box>

        {/* Third section on electrification options */}
        <Box
          minHeight={600}
          bg={theme.colors.black["500"]}
          display={"flex"}
          alignItems={"center"}
        >
          <Container maxW={"3xl"} minWidth={"xs"} paddingY={"2rem"}>
            <Box mt={{ base: "2rem", sm: "2rem" }}></Box>
            <Heading
              mx={{ sm: "2rem" }}
              color={"white"}
              fontSize={{ base: "6vw", sm: "3xl" }}
            >
              To fully electrify, most people have two options
            </Heading>

            <Flex mt="2rem" flexDir={{ base: "column", sm: "row" }}>
              <Spacer maxWidth={"2rem"} />
              <Box flex="1">
                <LightningIcon boxSize={"5rem"} color={theme.colors.red} />
                <Heading
                  color={theme.colors.red}
                  fontSize={{ base: "6vw", sm: "3xl" }}
                >
                  Upgrade wires & equipment
                </Heading>
                {upgradeOptions.map((option, i) => (
                  <Text
                    key={i}
                    fontWeight={"semibold"}
                    color={"white"}
                    fontSize={{ base: "4vw", xs: "xs", md: "sm" }}
                    mt="1rem"
                  >
                    {option}
                  </Text>
                ))}
              </Box>

              <Spacer minHeight={5} maxWidth={10} />

              <Box flex="1">
                <LightningIcon
                  boxSize={"5rem"}
                  color={theme.colors.green["50"]}
                />
                <Heading
                  color={theme.colors.green["50"]}
                  fontSize={{ base: "6vw", sm: "3xl" }}
                >
                  Become a SPAN home & save
                </Heading>
                {SPANOptions.map((option, i) => (
                  <Text
                    key={i}
                    fontWeight={"semibold"}
                    color={"white"}
                    fontSize={{ base: "4vw", xs: "xs", md: "sm" }}
                    mt="1rem"
                  >
                    {option}
                  </Text>
                ))}
                <Button
                  data-cy="explore-button-three"
                  mt={"2rem"}
                  onClick={() => {
                    router.push("/configurations/new")
                  }}
                  variant={"outline"}
                  border="1px solid white"
                  borderRadius={"20px"}
                  color="white"
                  size={{ xs: "sm", md: "md" }}
                  _hover={{ bg: theme.colors.gray["800"] }}
                >
                  Explore PowerUp
                </Button>
              </Box>
              <Spacer maxWidth={"2rem"} />
            </Flex>
            <Text
              mt={"2rem"}
              mb={"3rem"}
              mx={{ sm: "2rem" }}
              fontWeight={"semibold"}
              color={"grey"}
              fontSize={{ base: "4vw", xs: "xs" }}
            >
              {exploreLandingFooter}
            </Text>
          </Container>
        </Box>
      </Flex>
    </Box>
  )
}

export default Landing
